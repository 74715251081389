import { db } from "../firebase";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  query,
  orderBy,
  onSnapshot,
  limit,
  where,
} from "firebase/firestore";

const tacticsCollectionRef = collection(db, "tactics");

const createTactic = async (tacticData) => {
  try {
    await addDoc(tacticsCollectionRef, tacticData);
    return true;
  } catch (error) {
    return { error: error.message };
  }
};


const getLatestTactics = (max = 3) => {
  const q = query(tacticsCollectionRef, orderBy("updated", "desc"), limit(max));

  try {
    const latest = [];
    const latestT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        latest.push(doc);
      });
    });
    return latest;
  } catch (error) {
    //console.log(error);
    return null;
  }
};

const getFeaturedTactics = async (max = 3) => {
  //console.log("max = " + max);
  const q = query(
    tacticsCollectionRef,
    where("featured", "==", false),
    limit(max)
  );

  try {
    const featured = [];
    const featuredT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        featured.push(doc);
      });
    });
    return featured;
  } catch (error) {
    //console.log(error);
    return null;
  }
};

const getTopTactics = async (max = 3) => {
  const q = query(tacticsCollectionRef, orderBy("likes", "desc"), limit(max));

  try {
    const tops = [];
    const topT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        tops.push(doc);
      });
    });
    return tops;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const getTacticsbyFormation = async (max = 3, formation) => {
  //console.log("max = " + max + "and fom= " + formation);
  const q = query(
    tacticsCollectionRef,
    where("formation", "==", formation),
    limit(max)
  );

  try {
    const formationTactics = [];
    const formationT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        formationTactics.push(doc);
      });
    });
    return formationTactics;
  } catch (error) {
    //console.log(error);
    return null;
  }
};

const getTacticbyId = async (id) => {
 // console.log("tactic Id = " + id);

  try {
    const docRef = doc(db, "tactics", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
     // console.log("No such document!");
    }
  } catch (error) {
//console.log(error);
    return null;
  }
};

//User
const getMyTactics = async (userLink) => {
  //console.log("mu id =" + userLink);
  const q = query(tacticsCollectionRef, where("userLink", "==", userLink));

  try {
    const myTactic = [];
    const myT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        myTactic.push(doc);
      });
    });
    return myTactic;
  } catch (error) {
    //console.log(error);
    return null;
  }
};

const getTacticsbyHandler = async (username) => {
  //console.log("user =" + username);
  const q = query(tacticsCollectionRef, where("username", "==", username));

  try {
    const userTactics = [];
    const usT = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        userTactics.push(doc);
      });
    });
    return userTactics;
  } catch (error) {
    //console.log(error);
    return null;
  }
};

export {
  createTactic,
  getLatestTactics,
  getFeaturedTactics,
  getTopTactics,
  getMyTactics,
  getTacticsbyHandler,
  getTacticsbyFormation,
  getTacticbyId,
};
