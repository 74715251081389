import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { logInWithEmailAndPassword } from "../../firebase";
import AuthContext from "../../AuthContext";
import {Helmet} from 'react-helmet'

const LoginPage = () => {


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

 

  const handleSignIn = async (e) => {
    if (error) return;
    e.preventDefault();
    setEmail("");
    setPassword("");
    const res = await logInWithEmailAndPassword(email, password);
    if (res.error){
      setError(res.error);
    } else{
      if (user) navigate("/profile");
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validateEmail = (email) => {
    if (!isValidEmail(email)) {
      setError("Your email address appears to be invalid, please try again");
    } else {
      setError("");
    }
  };
  if (user) navigate("/profile");
  return (
    <>
          <Helmet>
      <title>Login | Fut Formations</title>
      <meta name="description" content="Improve your FIFA and EA Sports experience with expert tips and tricks on formations, tactics, and gameplay. Enhance your skills and dominate the virtual pitch." />
    </Helmet>
      <div className="page-box">
        <div className="flex flex--apart">
          <h1>Login</h1>
          <p className="subhead">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </div>
        <p>Login to create formations.</p>
        {error ? <p className="error">{error}</p> : null}
        <form className="form" onSubmit={handleSignIn}>
          <h3>Email</h3>
          <input
            type="email"
            name="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => validateEmail(e.target.value)}
          />
          <h3>Password</h3>
          <input
            type="password"
            name="password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="button-holder">
            <button>Sign In</button>
            <Link to="/reset">Forgot Password?</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginPage;
