import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import Header from './components/header';
import FormationView from './pages/formation-view';
import HomePage from './pages/homepage';
import CreatePage from './pages/create';
import LoginPage from './pages/login';
import Footer from './components/footer';
import ContactPage from './pages/contact';
import AboutPage from './pages/about';
import SignupPage from './pages/signup';
import ResetPage from './pages/reset';
import ProfilePage from './pages/profile';
import FormationByTypePage from './pages/formationsByType';
import BlogPage from './pages/blogs';
import BlogView from './pages/blogPost';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && <div className="bg"></div>}
      <div className="cover"></div>
      <div className="app">
        <BrowserRouter>
          <AuthProvider>
            <Header />
            <div className="wrapper">
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route path="formation/:id" element={<FormationView />} />
                <Route path="create" element={<CreatePage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="reset" element={<ResetPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="profile/:id" element={<ProfilePage />} />
                <Route
                  path="formations/:type"
                  element={<FormationByTypePage />}
                />
                <Route
                  path="formations/:type/:formation"
                  element={<FormationByTypePage />}
                />
                <Route path="blog" element={<BlogPage />} />
                <Route path="blog/:title" element={<BlogView />} />
              </Routes>
            </div>
            <Footer />
          </AuthProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
