import { Formation433 } from '../formationLayouts/433';
import { Formation442 } from '../formationLayouts/442';
import { Formation352 } from '../formationLayouts/352';
import { Formation3142 } from '../formationLayouts/3142';
import { Formation3412 } from '../formationLayouts/3412';
import { Formation3421 } from '../formationLayouts/3421';
import { Formation343 } from '../formationLayouts/343';
import { Formation3432 } from '../formationLayouts/343(2)';
import { Formation3511 } from '../formationLayouts/3511';
import { Formation4422 } from '../formationLayouts/442(2)';
import { Formation4332 } from '../formationLayouts/433(2)';
import { Formation4333 } from '../formationLayouts/433(3)';
import { Formation4334 } from '../formationLayouts/433(4)';
import { Formation4335 } from '../formationLayouts/433(5)';
import { Formation424 } from '../formationLayouts/424';
import { Formation4312 } from '../formationLayouts/4312';
import { Formation4222 } from '../formationLayouts/4222';
import { Formation41212 } from '../formationLayouts/41212';
import { Formation412122 } from '../formationLayouts/41212(2)';
import { Formation4132 } from '../formationLayouts/4132';
import { Formation4141 } from '../formationLayouts/4141';
import { Formation4231 } from '../formationLayouts/4231';
import { Formation42312 } from '../formationLayouts/4231(2)';
import { Formation4321 } from '../formationLayouts/4321';
import { Formation4411 } from '../formationLayouts/4411';
import { Formation451 } from '../formationLayouts/451';
import { Formation4512 } from '../formationLayouts/451(2)';
import { Formation5212 } from '../formationLayouts/5212';
import { Formation5122 } from '../formationLayouts/5122';
import { Formation523 } from '../formationLayouts/523';
import { Formation532 } from '../formationLayouts/532';
import { Formation541 } from '../formationLayouts/541';
import { Formation5412 } from '../formationLayouts/541(2)';

export const players = (animate, setPlayer) => {
  let playerArr = [];
  for (let index = 0; index < 11; index++) {
    playerArr.push(
      <div
        key={index}
        className={`player ${animate === index && 'animate'}`}
        onClick={() => setPlayer(index)}
      ></div>
    );
  }
  return playerArr;
};

const getPlayers = (formation, animate, setPlayer) => {
  switch (formation) {
    case '4-4-2':
      return (
        <Formation442
          modifier={'442'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-4-2(2)':
      return (
        <Formation4422
          modifier={'4422'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-3':
      return (
        <Formation433
          modifier={'433'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-3(2)':
      return (
        <Formation4332
          modifier={'4332'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-3(3)':
      return (
        <Formation4333
          modifier={'4333'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-3(4)':
      return (
        <Formation4334
          modifier={'4334'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-3(5)':
      return (
        <Formation4335
          modifier={'4335'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-2-4':
      return (
        <Formation424
          modifier={'424'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-1-2':
      return (
        <Formation4312
          modifier={'4312'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-3-2-1':
      return (
        <Formation4321
          modifier={'4321'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-2-2-2':
      return (
        <Formation4222
          modifier={'4222'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-1-2-1-2':
      return (
        <Formation41212
          modifier={'41212'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-1-2-1-2(2)':
      return (
        <Formation412122
          modifier={'412122'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-1-3-2':
      return (
        <Formation4132
          modifier={'4132'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-1-4-1':
      return (
        <Formation4141
          modifier={'4141'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-2-3-1':
      return (
        <Formation4231
          modifier={'4231'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-2-3-1(2)':
      return (
        <Formation42312
          modifier={'42312'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-4-1-1':
      return (
        <Formation4411
          modifier={'4411'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-4-1-1(2)':
      return (
        <Formation4411
          modifier={'4411'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-5-1':
      return (
        <Formation451
          modifier={'451'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '4-5-1(2)':
      return (
        <Formation4512
          modifier={'4512'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-5-2':
      return (
        <Formation352
          modifier={'352'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-5-1-1':
      return (
        <Formation3511
          modifier={'3511'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-1-4-2':
      return (
        <Formation3142
          modifier={'3142'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-4-1-2':
      return (
        <Formation3412
          modifier={'3412'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-4-2-1':
      return (
        <Formation3421
          modifier={'3421'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-4-3':
      return (
        <Formation343
          modifier={'343'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '3-4-3(2)':
      return (
        <Formation3432
          modifier={'3432'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-2-1-2':
      return (
        <Formation5212
          modifier={'5212'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-2-3':
      return (
        <Formation523
          modifier={'523'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-3-2':
      return (
        <Formation532
          modifier={'532'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-4-1':
      return (
        <Formation541
          modifier={'541'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-4-1(2)':
      return (
        <Formation5412
          modifier={'5412'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    case '5-1-2-2':
      return (
        <Formation5122
          modifier={'5122'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
    default:
      return (
        <Formation442
          modifier={'442'}
          animate={animate}
          setPlayer={setPlayer}
        />
      );
  }
};

export const Formation = ({ formation, large, animate, setPlayer }) => (
  <div className={`field ${large && 'field--large'}`}>
    <div className="field-bg" />
    {getPlayers(formation, animate, setPlayer)}
  </div>
);
