import { Link } from "react-router-dom";
import { useState } from "react";
import { sendEmailForPasswordReset } from "../../firebase";

import "./reset.css";

const ResetPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    setEmail("");
    setSuccess("");
    setError("");

    const res = await sendEmailForPasswordReset(email);
    setSuccess(
      "Reset password email has been sent, please check you email box."
    );

    if (res.error) {
      setError("There is no account with this email: " + email);
    }
  };

  return (
    <>
      <div className="page-box">
        <h1>Reset Your Password</h1>
        {error ? (
          <p className="error">{error}</p>
        ) : success ? (
          <p className="success">{success}</p>
        ) : (
          ""
        )}

        <form className="form" onSubmit={handleReset}>
          <h3>Email</h3>
          <input
            type="email"
            name="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="button-holder">
            <button>Reset</button>
            <Link to="/login">Go back</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPage;
