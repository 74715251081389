import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import AuthContext from '../../AuthContext';
import { Navigate } from 'react-router-dom';
import { FormationHolder } from '../../components/fomation-holder';
import { db } from '../../firebase';
import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
  limit,
} from 'firebase/firestore';
import Loader from '../../components/loader';

const ProfilePage = () => {
  const params = useParams();
  const [id, setId] = useState(params?.id || '');
  const [myProfile, setMyProfile] = useState(false);
  const [title, setTitle] = useState();
  const { user } = useContext(AuthContext);
  const [tactics, setTactics] = useState([]);
  const [myLikedtactics, setMyLikedTactics] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user.uid && !params?.id) {
      setId(user.uid);
    } else {
      setId(params.id);
    }
  }, [user, params.id]);

  useEffect(() => {
    if (id !== '') {
      setLoading(true);
      const tacticsCollectionRef = collection(db, 'tactics');
      const uq = query(tacticsCollectionRef, where('userLink', '==', id));
      onSnapshot(uq, (snapshot) => {
        setTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });

      //All my likes
      const docRef = collection(db, 'userlikes', id, 'likedtactics');
      const likedQ = query(docRef, orderBy('updated', 'desc'), limit(10));
      onSnapshot(likedQ, (snapshot) => {
        setMyLikedTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    }
  }, [id, params.id]);

  useEffect(() => {
    if (tactics.length > 0 && tactics[0]?.data?.userLink !== user?.uid) {
      setTitle(`${tactics[0].data?.username}'s formations`);
      setMyProfile(false);
    } else {
      setTitle('Your formations');
      setMyProfile(true);
    }
  }, [tactics, user, params.id]);

  if (!user && !params.id) {
    return <Navigate replace to="/login" />;
  }
  return (
    <>
      {!loading ? (
        <>
          {tactics?.length > 0 ? (
            <FormationHolder
              title={title}
              tactics={tactics}
              deleteable={myProfile}
            />
          ) : (
            <>
              <div className="wrapper">
                You do not have any formations yet.
                <br />
                <br />
                <Link to="/create">
                  <button>Create one here</button>
                </Link>
              </div>
            </>
          )}
          {myProfile && (
            <>
              <br />
              <br />
              <br />
              {myLikedtactics?.length > 0 ? (
                <FormationHolder
                  title="Liked Formations"
                  tactics={myLikedtactics}
                  deleteable={false}
                />
              ) : (
                <>
                  <div className="wrapper">
                    <br />
                    <br />
                    You do not have any liked formations yet.
                    <br />
                    <br />
                    <Link to="/formations/top">
                      <button>Top Formations</button>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ProfilePage;
