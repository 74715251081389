import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  getFirestore,setDoc,doc
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
// Login
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return true;
  } catch (error) {
    //console.log(error.message + " " + error.code)
    return { error: "Sorry, we can't find an account with this email address and Password" };
  }
};

//Sign Up
const signUpWithEmailAndPassword = async (
  userHandler,
  email,
  password,
  photoUrl
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    updateProfile(user, {
      displayName: userHandler,
      photoURL: photoUrl,
    })
      .then(() => {
         setDoc(doc(db, "handlers", userHandler), {
          userlink: user?.uid
        });
      })
      .catch((error) => {
        return { error: error.message };
      });
    return true;
  } catch (error) {
    return { error: error.message };
  }
};

//Reset Password
const sendEmailForPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return { error: error.message };
  }
};

//Sign out
const signUserOut = async () => {
  try {
    await signOut(auth);
    //console.log("loging out");
    return true;
  } catch (error) {
    return false;
  }
};


export {
  auth,
  db,
  logInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendEmailForPasswordReset,
  signUserOut,
};
