import { useState, useEffect } from 'react';
import { FormationHolder } from '../../components/fomation-holder';
import { db } from '../../firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
  where,
} from 'firebase/firestore';
import Loader from '../../components/loader';
import { Helmet } from 'react-helmet';

function HomePage() {
  const maxTactics = 3;
  const [freshTactics, setFreshTactics] = useState([]);
  const [featuredTactics, setFeaturedTactics] = useState([]);
  const [topTactics, setTopTactics] = useState([]);

  useEffect(() => {
    const tacticsCollectionRef = collection(db, 'tactics');
    const q = query(
      tacticsCollectionRef,
      orderBy('updated', 'desc'),
      limit(maxTactics)
    );
    onSnapshot(q, (snapshot) => {
      setFreshTactics(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
    const lq = query(
      tacticsCollectionRef,
      orderBy('updated', 'desc'),
      limit(maxTactics)
    );
    onSnapshot(lq, (snapshot) => {
      setFreshTactics(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    const fq = query(
      tacticsCollectionRef,
      where('featured', '==', true),
      limit(maxTactics)
    );
    onSnapshot(fq, (snapshot) => {
      setFeaturedTactics(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    const tq = query(
      tacticsCollectionRef,
      orderBy('likes', 'desc'),
      limit(maxTactics)
    );
    onSnapshot(tq, (snapshot) => {
      setTopTactics(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  const allLoaded =
    featuredTactics.length > 0 &&
    topTactics.length > 0 &&
    freshTactics.length > 0;

  return (
    <>
      <Helmet>
        <title>
          EA Sports FC & FIFA Formations and Tactics | Fut Formations
        </title>
        <meta
          name="description"
          content="Improve your FIFA and EA Sports FC experience with expert tips and tricks on formations, tactics, and gameplay. Enhance your skills and dominate the virtual pitch."
        />
      </Helmet>
      {!allLoaded ? (
        <Loader />
      ) : (
        <>
          <h1>
            Welcome to <span>FUT</span>Formations for FIFA 23<span>.</span>
          </h1>
          <p className="tagline">
            Bid farewell to pausing YouTube videos, embrace seamless formation
            analysis with <span>Fut</span>Formations!
          </p>
          {featuredTactics?.length > 0 && (
            <FormationHolder
              title="Featured"
              tactics={featuredTactics}
              details={true}
            />
          )}
          {topTactics?.length > 0 && (
            <FormationHolder
              title="Top Rated"
              tactics={topTactics}
              details={true}
            />
          )}
          {freshTactics?.length > 0 && (
            <FormationHolder
              title="Fresh"
              tactics={freshTactics}
              details={true}
            />
          )}
          <div className="formation-holder">
            <h2>From the Blog</h2>
            <p>
              For years, FIFA, the most well-known soccer video game in the
              world, has given players a realistic simulation of the game. EA
              Sports adds new features and tweaks to the gaming mechanics with
              each new version of the game, including the option to select from
              a number of formations to fit your playing style. We will examine
              the top 5 FIFA formations used in EA Sports FC in this blog post.
              <a href="/blog/The-Top-5-FIFA-Formations-Used-in-EA-Sports-FC">
                {' '}
                Read more
              </a>
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default HomePage;
