import { FormationCard } from '../formationCard';
import './formation-holder.css';

export const FormationHolder = ({ title, tactics, deleteable, details }) => (
  <div className="formation-holder">
    <h2>{title}</h2>
    <div className={`formations ${details && 'formations--details'}`}>
      {tactics.map((formData, i) => {
        const form = formData.data;
        const date = new Date(0);
        date.setUTCSeconds(form.updated.seconds);
        return (
          <FormationCard
            key={i}
            name={form.name}
            link={formData.id}
            formation={form.formation}
            username={form.username}
            userLink={form.userLink}
            style={form.style}
            version={form.version}
            date={date.toLocaleDateString()}
            likes={form.likes}
            deleteable={deleteable}
            formData={formData.data}
            details={details}
          />
        );
      })}
    </div>
  </div>
);
