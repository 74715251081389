import React, { useState } from 'react';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import axios from 'axios';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validateEmail = (email) => {
    if (!isValidEmail(email)) {
      setEmailError(
        'Your email address appears to be invalid, please try again'
      );
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError !== '') {
      return;
    }
    setLoader(true);
    const contactRef = collection(db, 'contactform');
    var contactData = {
      name: name,
      email: email,
      message: message,
      created: serverTimestamp(),
    };

    try {
      await addDoc(contactRef, contactData);
      setLoader(false);
      setName('');
      setEmail('');
      setMessage('');
      setSuccess("Thank you for contacting us, we'll reach out to you soon");
      axios
        .post(
          'https://us-central1-fut-formations.cloudfunctions.net/submit',
          contactData
        )
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      setLoader(false);
      setError('Sorry there was an issue submitting the form.');
    }
  };
  return (
    <div className="page-box">
      <h1>Contact Us</h1>
      {error ? <p className="error">{error}</p> : null}
      {success ? <p className="success">{success}</p> : null}
      <form className="form" onSubmit={handleSubmit}>
        <h3>Name</h3>
        <input
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <h3>Email</h3>
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => validateEmail(e.target.value)}
        />
        <span className="error">{emailError}</span>
        <h3>Message</h3>
        <textarea
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength="500"
        ></textarea>
        <div className="button-holder">
          <button>Send Message</button>
        </div>
      </form>
    </div>
  );
};

export default ContactPage;
