import './formation-view.css';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Formation } from '../../components/formation';
import AuthContext from '../../AuthContext';

import { db } from '../../firebase';
import { setDoc, getDoc, doc, updateDoc, increment } from 'firebase/firestore';
import Loader from '../../components/loader';
import { Helmet } from 'react-helmet';

function FormationView() {
  const params = useParams();
  const [player, setPlayer] = useState(0);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState();
  const [likes, setLikes] = useState(0);
  const [stopLike, setStopLike] = useState(false);
  const [liked, setLiked] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDoc(doc(db, 'tactics', params.id)).then((docSnap) => {
      if (docSnap.exists()) {
        setData(docSnap.data());
        setLikes(docSnap.data().likes);
      }
    });
  }, [params.id]);

  useEffect(() => {
    //If user viewing his Tatics then no  like
    if (data?.userLink === user?.uid) {
      console.log("mine, can't like :(");
      setStopLike(true);
    }
    // only call firebase to see if the current user liked in past then no like button click
    else if (user) {
      getDoc(doc(db, 'userlikes', user.uid, 'likedtactics', params.id)).then(
        (docSnap) => {
          if (docSnap.exists()) {
            console.log('already Liked it :)');
            setStopLike(true);
          }
        }
      );
    }
  }, [data]);

  const showPlayerOptions = (player) => {
    let options = '';
    const sorted = Object.keys(player)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = player[key];

        return accumulator;
      }, {});
    for (let [key, value] of Object.entries(sorted)) {
      options += `<div class="flex flex--apart"><span>${key}:</span> <div class="selecter"><span>${value}</span></div></div>`;
    }
    return options;
  };

  const likeTactic = async () => {
    if (!user) {
      setPopupOpen(true);
    } else {
      const tacRef = doc(db, 'tactics', params.id);
      await updateDoc(tacRef, {
        likes: increment(1),
      });
      setLiked(true);
      setLikes(likes + 1);

      // update likes table
      var tacticData = {
        name: data.name,
        style: data.style,
        username: data.username,
        userLink: data.userLink,
        version: data.version,
        formation: data.formation,
        updated: data.updated,
      };
      const userlikedRef = doc(
        db,
        'userlikes',
        user.uid,
        'likedtactics',
        params.id
      );
      await setDoc(userlikedRef, tacticData);
    }
  };

  const getDate = () => {
    const date = new Date(0);
    date.setUTCSeconds(data.updated.seconds);
    return date.toLocaleDateString();
  };

  return (
    <>
      <Helmet>
        <title>Tactics | Fut Formations</title>
        <meta
          name="description"
          content="Discover the secrets of winning in FIFA and EA Sports with in-depth guides and tutorials on tactics, formations, and gameplay. Get the edge over your opponents and dominate the virtual pitch."
        />
      </Helmet>
      <div className={`overlay ${popupOpen && 'overlay--open'}`}>
        <div className="popup">
          <>
            <h2>Like formation</h2>
            <div>To like a formation you must create an account</div>
            <div className="button-holder">
              <Link to="/login">
                <button>Login</button>
              </Link>
              <button onClick={() => setPopupOpen(false)}>Close</button>
            </div>
          </>
        </div>
      </div>
      {data && data.userLink ? (
        <div className="formation-view">
          <div className="pitch-view">
            <Formation
              name={data.username}
              formation={data.formation}
              large={true}
              animate={player}
              setPlayer={setPlayer}
            />
            <div className="player-info">
              <h2>{data.playerOptions[player].name}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: showPlayerOptions(data.playerOptions[player].options),
                }}
              />
            </div>
          </div>
          <div className="stats">
            <h1>{data.name}</h1>
            <h2>{data.formation}</h2>
            <Link to={`/profile/${data.userLink}`} className="username">
              @{data.username}
            </Link>
            {stopLike || liked ? (
              <span className="likes">
                {likes} {likes > 1 ? 'Likes' : 'Like'}
              </span>
            ) : (
              <a href="#" onClick={() => likeTactic()} className="likes">
                Like formation
              </a>
            )}
            <h1>Defence</h1>
            <p>
              <span>Defensive style: </span> {data.defStyle}
            </p>
            <p>
              <span>Width:</span> {data.defWidth}%
            </p>
            <p>
              <span>Depth:</span> {data.defDepth}%
            </p>
            <h1>Offence</h1>
            <p>
              <span>Build up play:</span> {data.buildUpPlay}
            </p>
            <p>
              <span>Chance Creation:</span> {data.chanceCreation}
            </p>
            <p>
              <span>Width:</span> {data.offWidth}%
            </p>
            <p>
              <span>Players in box:</span> {data.playersInBox}/10
            </p>
            <p>
              <span>Corners:</span> {data.corners}/5
            </p>
            <p>
              <span>Free kicks:</span> {data.freeKicks}/5
            </p>
            {data.description && (
              <>
                <h1>Description</h1>
                <p>{data.description}</p>
              </>
            )}
            {data.youtube && (
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${data.youtube}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}
            <p className="label">{data.style}</p>
            <p className="game-tag">Fifa {data.version}</p>
            <p className="date">Posted {getDate()}</p>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default FormationView;
