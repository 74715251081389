function AboutPage() {
  return (
    <div className="page-box">
      <h1>About FUT Formations</h1>
      <p>
        FUT Formations was created as a space to find all of the latest
        information on Fifa formations. When playing on other consoles or moving
        from one version of the game to another I would use my phone to
        screenshot my formations to make sure I had everything correct, this
        website will allow you to create and save your favorite formations so
        that you never forget it again.
      </p>
      <p>
        I would also spend hours looking through youtube for the latest and
        greatest meta formations to use and found it hard seeing what was the
        latest and what people were finding useful, this website will allow you
        to rate formations so that you can see which are working the best for
        the community.
      </p>
    </div>
  );
}

export default AboutPage;
