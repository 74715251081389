import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FormationHolder } from '../../components/fomation-holder';
import { db } from '../../firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
  where,
} from 'firebase/firestore';
import Loader from '../../components/loader';
import {Helmet} from 'react-helmet'

const FormationByTypePage = ({ type, max = 9, formation }) => {
  const maxTactics = max;
  const [tactics, setTactics] = useState([]);
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    setTactics([]);

    const tacticsCollectionRef = collection(db, 'tactics');

    if (params.type === 'featured') {
      setLoading(true);
      setTitle('Featured');
      const lq = query(
        tacticsCollectionRef,
        where('featured', '==', true),
        limit(maxTactics)
      );
      onSnapshot(lq, (snapshot) => {
        setTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
    }

    if (params.type === 'fresh') {
      setTitle('Fresh');
      const fq = query(
        tacticsCollectionRef,
        orderBy('updated', 'desc'),
        limit(maxTactics)
      );
      onSnapshot(fq, (snapshot) => {
        setTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
    }

    if (params.type === 'top') {
      setTitle('Top Rated');
      const tq = query(
        tacticsCollectionRef,
        orderBy('likes', 'desc'),
        limit(maxTactics)
      );
      onSnapshot(tq, (snapshot) => {
        setTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
    }

    if (params.type === 'formation') {
      setLoading(true);
      setTitle('Formations');
      const byq = query(
        tacticsCollectionRef,
        where('formation', '==', params.formation),
        limit(9)
      );
      onSnapshot(byq, (snapshot) => {
        setTactics(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
    }
  }, [params.type, params.formation]);

  return (
    <>
    <Helmet>
      <title>Featured & Latest Tactics | Fut Formations</title>
      <meta name="description" content="Stay ahead of the game with the latest and greatest FIFA and EA Sports formations and tactics. Browse our featured, top-rated, and newly added tactics to dominate the virtual pitch." />
    </Helmet>
      {!loading ? (
        tactics.length > 0 ? (
          <FormationHolder title={title} tactics={tactics} details={true} />
        ) : (
          'No tactics for this formation yet.'
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default FormationByTypePage;
