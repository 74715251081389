import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { signUserOut } from '../../firebase';
import AuthContext from '../../AuthContext';
import './header.css';

const Header = () => {
  const { user } = useContext(AuthContext);
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (user) {
      setUsername(user.displayName);
    }
  }, [user]);

  const [data, setData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const closeNavs = () => {
    setDropdownOpen(false);
    setMobileNavOpen(false);
  };

  useEffect(() => {
    if (data.length < 1) {
      fetch(`/formations.json`)
        .then((response) => response.json())
        .then((data) => setData(data.formations));
    }
  }, [data]);

  const handleSignOut = async () => {
    await signUserOut();
  };

  return (
    <>
      <div className="user-header">
        {user ? (
          <>
            <span> Hello, {username}</span>
            <Link to="/profile">Profile</Link>
            <Link to="#" onClick={handleSignOut}>
              Logout
            </Link>
          </>
        ) : (
          <>
            <Link to="/login" onClick={() => closeNavs()}>
              Login
            </Link>
            <Link to="/signup" onClick={() => closeNavs()}>
              Signup
            </Link>
          </>
        )}
      </div>
      <header>
        <Link to="/" className="logo">
          <span>FUT</span>
          <span>Formations</span>
        </Link>

        <div className={`login-nav ${mobileNavOpen && 'login-nav--open'}`}>
          <Link to="/formations/featured" onClick={() => closeNavs()}>
            Featured
          </Link>
          <Link to="/formations/top" onClick={() => closeNavs()}>
            Top Rated
          </Link>
          <Link to="/formations/fresh" onClick={() => closeNavs()}>
            Latest
          </Link>
          <a
            href="#"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={`dropdown-button ${
              dropdownOpen && 'dropdown-button--open'
            }`}
          >
            By Formation
            <svg
              className="accordion-arrow"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.04085 0.83331C7.38906 0.832905 7.72059 0.973505 7.95071 1.21918L13.3118 6.16088C13.3118 6.16088 13.5996 6.46537 13.6624 6.89489C13.7095 7.21707 13.6003 7.62712 13.3118 7.93542C13.0525 8.21255 12.7318 8.31356 12.4474 8.2971C11.9936 8.27085 11.6173 7.99073 11.6173 7.99073L7.13978 4.04782C7.1148 4.02103 7.07874 3.98865 7.04085 3.98865C7.00295 3.98865 6.96689 4.02103 6.94191 4.04782L2.42731 7.99225C2.42731 7.99225 2.08562 8.2933 1.64493 8.33026C1.34816 8.35516 0.989519 8.22522 0.724218 7.93542C0.446084 7.6316 0.301779 7.21342 0.344496 6.89489C0.403031 6.45841 0.724218 6.16088 0.724218 6.16088L6.1294 1.22116C6.35992 0.974889 6.6919 0.833622 7.04085 0.83331Z"
                fill="#0090ff"
              />
            </svg>
          </a>{' '}
          <Link to="/blog/" onClick={() => closeNavs()}>
            Blog
          </Link>
          <div
            className={`mobile-dropdown ${
              dropdownOpen && 'mobile-dropdown--open'
            }`}
          >
            {data.length > 0 &&
              data.map((formation, i) => (
                <Link
                  key={i}
                  to={`/formations/formation/${formation.name}`}
                  onClick={() => closeNavs()}
                >
                  {formation.name}
                </Link>
              ))}
          </div>
          <Link to="/create" onClick={() => closeNavs()}>
            <button>Create Formation</button>
          </Link>
          <Link
            className="mobile-only"
            to="/contact"
            onClick={() => closeNavs()}
          >
            <button>Contact</button>
          </Link>
        </div>
        <div
          className="mobile-only mobile-nav"
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <div />
          <div />
          <div />
        </div>
      </header>
      <div className={`dropdown ${dropdownOpen && 'dropdown--open'}`}>
        {data.length > 0 &&
          data.map((formation, i) => (
            <Link
              key={i}
              to={`/formations/formation/${formation.name}`}
              onClick={() => closeNavs()}
            >
              {formation.name}
            </Link>
          ))}
      </div>
    </>
  );
};
export default Header;
