import { Helmet } from 'react-helmet';

function BlogPage() {
  return (
    <>
    <Helmet>
        <title>
        Unlock the Thrills of EA Sports and FIFA | Fut Formations
        </title>
        <meta
          name="description"
          content="Discover the exhilarating world of EA Sports and FIFA on our blog landing page. Immerse yourself in the latest news, updates, and expert insights about the beloved sports gaming franchise."
        />
      </Helmet>
      <div className="page-box">
        <div className="blog-post">
          <a href="/blog/The-Top-5-FIFA-Formations-Used-in-EA-Sports-FC">
            <h1>The Top 5 FIFA Formations Used in EA Sports FC</h1>
          </a>
          <p>
            For years, FIFA, the most well-known soccer video game in the world,
            has given players a realistic simulation of the game...
          </p>
          <a href="/blog/The-Top-5-FIFA-Formations-Used-in-EA-Sports-FC">
            Read More
          </a>
        </div>
      </div>
      <br />
      <div className="page-box">
        <div className="blog-post">
          <a href="/blog/Mastering-EA-Sports-FC-Tactics-Unlocking-Your-Potential-for-Peak-Performance">
            <h1>
              Mastering FIFA Tactics: Unlocking Your Potential for Peak
              Performance
            </h1>
          </a>
          <p>
            EA Sports FC, the legendary football video game series, has taken
            the gaming world by storm. With its realistic gameplay, stunning
            graphics, and immersive features, it has captured the hearts of
            millions of players worldwide...
          </p>

          <a href="/blog/Mastering-EA-Sports-FC-Tactics-Unlocking-Your-Potential-for-Peak-Performance">
            Read More
          </a>
        </div>
      </div>
      <br />
      <div className="page-box">
        <div className="blog-post">
          <a href="/blog/EA-Sports-FC-The-Evolution-of-a-Gaming-Icon">
            <h1>EA Sports FC: The Evolution of a Gaming Icon</h1>
          </a>
          <p>
            In a surprising move that sent shockwaves through the gaming
            community, the beloved FIFA video game franchise has undergone a
            transformative rebranding...
          </p>

          <a href="/blog/EA-Sports-FC-The-Evolution-of-a-Gaming-Icon">
            Read More
          </a>
        </div>
      </div>
    </>
  );
}

export default BlogPage;
