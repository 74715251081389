import { useState, useEffect, useContext } from 'react';
import { Formation } from '../../components/formation';
import { createTactic } from '../../Utility/tactics';
import AuthContext from '../../AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';

import './create.css';
import '../formation-view/formation-view.css';
import { serverTimestamp } from 'firebase/firestore';
import Loader from '../../components/loader';

function CreatePage() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);
  const [playerOptions, setPlayerOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(0);

  const [error, setError] = useState(null);
  //formation
  const [formation, setFormation] = useState('4-4-2');
  const [name, setName] = useState();
  //defense
  const [defWidth, setDefWidth] = useState(50);
  const [defDepth, setDefDepth] = useState(50);
  //offense

  const [offWidth, setOffWidth] = useState(50);
  const [playersInBox, setPlayersInBox] = useState(5);
  const [corners, setCorners] = useState(5);
  const [freekicks, setFreeKicks] = useState(5);

  const [popupOpen, setPopupOpen] = useState(false);

  const isValidUsername = (name) => {
    const username = String(name);
    if (!/([A-Za-z0-9-])$/.test(username) || !/^\S+$/.test(username)) {
      return setError(
        'Your formation name must contain only letters, numbers and -'
      );
    } else if (username.length < 5) {
      return setError('Your formation name must contain atleast 5 characters');
    } else {
      return setError('');
    }
  };

  useEffect(() => {
    if (!data && !loading) {
      setLoading(true);
      fetch(`./formations.json`)
        .then((response) => response.json())
        .then((data) => setData(data.formations))
        .then(setLoading(false));

      fetch(`./formations/playerOptions.json`)
        .then((response) => response.json())
        .then((data) => setOptions(data.playerOptions))
        .then(setLoading(false));
    }
  }, [data, formation, loading]);

  useEffect(() => {
    if (formation) {
      fetch(`./formations/${formation}.json`)
        .then((response) => response.json())
        .then((formationData) => setPlayerOptions(formationData.playerOptions));
    }
  }, [formation]);

  const navigate = useNavigate();

  if (!user) {
    return <Navigate replace to="/login" />;
  }
  return (
    <div className="create">
      <div className={`overlay ${popupOpen && 'overlay--open'}`}>
        <Loader />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (e.target.name.value === '' || error !== '') {
            window.scrollTo(0, 0);
            return setError('Please name your formation.');
          }
          setPopupOpen(true);
          const values = {
            name: e.target.name.value,
            username: user && user.displayName,
            userLink: user && user.uid,
            likes: 1,
            featured: false,
            youtube: e.target.youtube.value,
            formation: e.target.formation.value,
            defStyle: e.target.defStyle.value,
            defWidth: e.target.defWidth.value,
            defDepth: e.target.defDepth.value,
            buildUpPlay: e.target.buildUpPlay.value,
            chanceCreation: e.target.chanceCreation.value,
            offWidth: e.target.offWidth.value,
            playersInBox: e.target.playersInBox.value,
            corners: e.target.corners.value,
            freeKicks: e.target.freeKicks.value,
            description: e.target.description.value,
            style: e.target.style.value,
            version: e.target.version.value,
            updated: serverTimestamp(),
            playerOptions: [],
          };
          const selctedPlayerOptions =
            document.querySelectorAll('.player-options');
          [...selctedPlayerOptions].map((playersOp, i) => {
            const selctedPlayerOption = playersOp.querySelectorAll('select');
            const obj = {
              name: playerOptions[i].name,
              order: playerOptions[i].order,
              options: {},
            };
            [...selctedPlayerOption].map((op, j) => {
              //console.log(op.name, op.value);
              obj.options[op.name] = op.value;
            });
            values.playerOptions.push(obj);
          });
          // console.log(values);
          createTactic(values);
          setTimeout(() => {
            navigate('/profile');
          }, 2000);
        }}
      >
        <div className="flex flex--50">
          <div className="instructions">
            <h1>Pick a formation.</h1>
            <div className="selecter formation-selecter">
              {data !== null && !loading ? (
                <select
                  name="formation"
                  onChange={(e) => setFormation(e.target.value)}
                >
                  {data.map((form, i) => (
                    <option
                      value={form.name}
                      selected={formation === form.name}
                    >
                      {form.name}
                    </option>
                  ))}
                </select>
              ) : (
                <select name="formation">
                  <option>Loading..</option>
                </select>
              )}
            </div>
            <p>
              <div className="formation-view mobile-only">
                <div className="pitch-view">
                  <Formation formation={formation} large={true} />
                </div>
              </div>
            </p>
            <p>
              <span>Name your formation*:</span>
              <input
                type="text"
                className="form-input"
                name="name"
                onBlur={(e) => isValidUsername(e.target.value)}
              />
            </p>
            <span className="error">{error}</span>
            <h1>Select the team instructions.</h1>
            <div>
              <p>
                <span>Defensive Style:</span>
                <div className="selecter">
                  <select name="defStyle">
                    <option selected>Balanced</option>
                    <option>Pressure on heavy touch</option>
                    <option>Pressure after possession loss</option>
                    <option>Constant pressure</option>
                    <option>Drop back</option>
                  </select>
                </div>
              </p>
              <p>
                <span>Width:</span>
                <input
                  id="defWidth"
                  name="defWidth"
                  type="range"
                  defaultValue="50"
                  min="1"
                  max="100"
                  onChange={(e) => setDefWidth(e.target.value)}
                />
                <span>{defWidth}/100</span>
              </p>
              <p>
                <span>Depth:</span>
                <input
                  id="defDepth"
                  name="defDepth"
                  type="range"
                  defaultValue="50"
                  min="1"
                  max="100"
                  onChange={(e) => setDefDepth(e.target.value)}
                />
                <span>{defDepth}/100</span>
              </p>
            </div>
            <div>
              <p>
                <span>Build up play:</span>
                <div className="selecter">
                  <select name="buildUpPlay">
                    <option selected>Balanced</option>
                    <option>Slow build up</option>
                    <option>Fast build up</option>
                    <option>Long ball</option>
                  </select>
                </div>
              </p>
              <p>
                <span>Chance creation:</span>
                <div className="selecter">
                  <select name="chanceCreation">
                    <option selected>Balanced</option>
                    <option>Possession</option>
                    <option>Forward runs</option>
                    <option>Direct passing</option>
                  </select>
                </div>
              </p>
              <p>
                <span>Width:</span>
                <input
                  name="offWidth"
                  type="range"
                  defaultValue="50"
                  min="1"
                  max="100"
                  onChange={(e) => setOffWidth(e.target.value)}
                />
                <span>{offWidth}/100</span>
              </p>
              <p>
                <span>Players in the box:</span>
                <input
                  name="playersInBox"
                  type="range"
                  defaultValue="5"
                  min="1"
                  max="10"
                  onChange={(e) => setPlayersInBox(e.target.value)}
                />
                <span>{playersInBox}/10</span>
              </p>
              <p>
                <span>Corners:</span>
                <input
                  name="corners"
                  type="range"
                  defaultValue="5"
                  min="1"
                  max="5"
                  onChange={(e) => setCorners(e.target.value)}
                />
                <span>{corners}/5</span>
              </p>
              <p>
                <span>Free kicks:</span>
                <input
                  name="freeKicks"
                  type="range"
                  defaultValue="5"
                  min="1"
                  max="5"
                  onChange={(e) => setFreeKicks(e.target.value)}
                />
                <span>{freekicks}/5</span>
              </p>
              <h1>Describe your formation.</h1>
              <p>
                <span>Description:</span>
                <textarea
                  name="description"
                  className="form-input"
                  maxLength="100"
                />
              </p>
              <p>
                <span>Style:</span>
                <div className="selecter">
                  <select name="style">
                    <option value="Attacking">Attacking</option>
                    <option value="Defensive">Defensive</option>
                  </select>
                </div>
              </p>
              <p>
                <span>Fifa version:</span>
                <div className="selecter">
                  <select name="version">
                    <option selected value="23">
                      Fifa 23
                    </option>
                    <option value="22">Fifa 22</option>
                  </select>
                </div>
              </p>
              <p>
                <span>Youtube url:</span>
                <input
                  name="youtube"
                  type="text"
                  className="form-input"
                  placeholder="/XHEYZ"
                />
              </p>
            </div>
            <button type="submit" className="desktop-only">
              Create Formation
            </button>
          </div>
          <div>
            <div className="sticky">
              <h1>Set player instructions:</h1>
              <div className="formation-view">
                <div className="pitch-view">
                  <Formation
                    formation={formation}
                    large={true}
                    animate={player}
                    setPlayer={setPlayer}
                  />
                </div>
              </div>
              {playerOptions && options && (
                <div className="player-info">
                  {playerOptions.map((playerOp, i) => (
                    <div
                      className="player-options"
                      style={
                        player === i
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <h2>{playerOp.name}</h2>
                      {playerOp.options.map((option, j) => (
                        <div className="flex flex--apart">
                          <span>{options[option]?.title}</span>
                          <div className="selecter">
                            <select name={options[option]?.title}>
                              {options[option]?.options?.map((subOption, j) => (
                                <option>{subOption}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <button type="submit" className="mobile-only">
            Create Formation
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreatePage;
