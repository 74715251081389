import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => (
  <footer>
    © FUTFormations Copyright {new Date().getFullYear()}
    <div className="login-nav">
      <Link to="/contact">Contact</Link>
    </div>
  </footer>
);

export default Footer;
