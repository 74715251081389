import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db, signUpWithEmailAndPassword } from '../../firebase';
import { getDoc, doc } from 'firebase/firestore';
import Loader from '../../components/loader';
import {Helmet} from 'react-helmet'

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [pwError, setPwError] = useState('');
  const [passwordc, setPasswordc] = useState('');
  const [pwcError, setPwcError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if ((usernameError, emailError || pwError || pwcError)) {
      setError('Please correct any form errors');
    } else {
      setLoading(true);
      setError('');
      setUsername('');
      setEmail('');
      setPassword('');
      setPasswordc('');
      const res = await signUpWithEmailAndPassword(
        username,
        email,
        password,
        'defaultIcon.png'
      );
      if (res.error) {
        setLoading(false);
        window.scrollTo(0, 0);
        if (res.error.includes('auth/email-already-in-use')) {
          setError(
            'Sorry, an account with the given email address already exists.'
          );
        } else if (res.error.includes('auth/invalid-email')) {
          setError('Sorry, email address is not valid..');
        } else if (res.error.includes('auth/operation-not-allowed')) {
          setError(
            "Sorry, an account can't be created at this time. please contact us"
          );
        } else if (res.error.includes('auth/weak-password')) {
          setError('Sorry, the password is not strong enough.');
        } else {
          setError("Sorry, an account can't be created at this time.");
        }
      } else {
        navigate('/profile');
      }
    }
  };

  const isValidUsername = (username) => {
    if (!/([A-Za-z0-9-])$/.test(username) || !/^\S+$/.test(username)) {
      setUsernameError(
        'Your username must contain only letters, numbers and -'
      );
    } else if (username.length < 5) {
      setUsernameError('Your username must contain atleast 5 characters');
    } else {
      getDoc(doc(db, 'handlers', username)).then((docSnap) => {
        if (docSnap.exists()) {
          return setUsernameError(
            'That username is taken. Please try another one.'
          );
        }
      });
      setUsernameError('');
    }
  };

  const isValidPassword = (pass) => {
    const Requirement1 = !!(pass.length >= 8);
    const Requirement2 = !!pass.match(/[A-z]/);
    const Requirement3 = !!pass.match(/\d/);
    const Requirement4 = !/([ ])$/.test(pass);
    if (!Requirement1) {
      setPwError('Your password must contain at least 8 characters');
    } else if (!Requirement2) {
      setPwError('Your password must contain one letter');
    } else if (!Requirement3) {
      setPwError('Your password must contain one number');
    } else if (!Requirement4) {
      setPwError('Your password must not contain a space');
    } else {
      setPwError('');
    }
  };

  const isPasswordSame = (pass) => {
    if (pass !== password) {
      setPwcError('Your passwords do not match');
    } else {
      setPwcError('');
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validateEmail = (email) => {
    if (!isValidEmail(email)) {
      setEmailError(
        'Your email address appears to be invalid, please try again'
      );
    } else {
      setEmailError('');
    }
  };

  return (
    <>
    <Helmet>
      <title>Create an Account | Fut Formations</title>
      <meta name="description" content="Improve your FIFA and EA Sports experience with expert tips and tricks on formations, tactics, and gameplay. Enhance your skills and dominate the virtual pitch." />
    </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-box">
          <h1>Create an account</h1>
          <p>Create an account to save and share your formations.</p>
          {error ? <p className="error">{error}</p> : null}

          <form onSubmit={handleSignUp} className="form">
            <h3>Username</h3>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={(e) => isValidUsername(e.target.value)}
              required
            />
            {usernameError && <span className="error">{usernameError}</span>}

            <h3>Email</h3>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => validateEmail(e.target.value)}
              required
            />
            {emailError && <span className="error">{emailError}</span>}

            <h3>Password</h3>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => isValidPassword(e.target.value)}
            />
            {pwError ? (
              <span className="error">{pwError}</span>
            ) : (
              <span className="helper">
                8 characters including one letter and one number
              </span>
            )}

            <h3>Re-type Password</h3>
            <input
              type="password"
              value={passwordc}
              onChange={(e) => setPasswordc(e.target.value)}
              onBlur={(e) => isPasswordSame(e.target.value)}
            />
            {pwcError && <span className="error">{pwcError}</span>}

            <div className="button-holder">
              <button>Sign up</button>
              Already Have an Account? <Link to="/login">Sign In</Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default SignupPage;
