import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function BlogView() {
  const [blogPost, setBlogPost] = useState();
  const params = useParams();

  useEffect(() => {
    if (!blogPost) {
      fetch(`/blogs/${params.title}.json`)
        .then((response) => response.json())
        .then((blogData) => setBlogPost(blogData));
    }
  }, []);

  return (
    <>
    {blogPost && (
        <>
        <Helmet>
          <title>
          {blogPost.title} | Fut Formations
          </title>
          <meta
            name="description"
            content={blogPost.description}
          />
      </Helmet>
        </>
      )}
          
    <div className="page-box">
      {blogPost && (
        <>
          <h1>{blogPost.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: blogPost.body }}></div>
        </>
      )}
    </div>
    </>
  );
}

export default BlogView;
